import React from 'react';
import clsx from 'clsx';
import { Button, Separator } from '@/components';
import { trackButtonClick } from '@/services/analytics';
import Funcionalidade from './Funcionalidade';
import Trigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import * as styles from './card.module.scss';
import { Plano, PlanoNames } from '@/constants/planos';
import { formatPrice } from '@/utils/formatPrice';
import { navigate } from 'gatsby-link';
import { routes } from '@/constants/routes';
import Info from '@/assets/svg/info.svg';

export interface CardProps {
  plano: Plano;
  gaMetadata: { event: string };
}

const Card = ({ plano, gaMetadata: { event } }: CardProps) => {
  const handleTestar = () => {
    navigate(routes.cadastroAnual(plano.isAnual));
    trackButtonClick({ event, buttonName: plano.gaMetadata.tryButtonName });
  };

  const handleSaibaMais = () => {
    trackButtonClick({ event, buttonName: plano.gaMetadata.tryButtonName });
    navigate(routes.redesFranquias);
  };

  const valor = plano.promo || plano.valor || 0;

  const isPlanoRedes = plano.nome === PlanoNames.Redes;

  const isAnual = !!plano.isAnual;

  return (
    <div
      className={clsx(styles.container, {
        [styles.popular]: plano.mostPopular,
        [styles.preview]: plano.preview,
      })}
    >
      {plano.mostPopular && <div className={styles.badgePopular}>Mais popular</div>}
      {plano.preview && <div className={styles.badgePreview}>Pré-lançamento</div>}
      <div className={styles.header}>
        <h2>{plano.nome}</h2>
        <p>{plano.descricao}</p>

        <div className={styles.priceContainer}>
          {plano.valor && (
            <>
              {(plano.promo || plano.aPartirDe) && (
                <p className={styles.fullPrice}>
                  {plano.promo && plano.aPartirDe && (
                    <>
                      A partir de <span>{formatPrice(plano.valor)}</span>
                    </>
                  )}
                  {plano.promoDescription && (
                    <span className={styles.promoDesc}>{plano.promoDescription}</span>
                  )}
                  {plano.promo && !plano.aPartirDe && (
                    <>
                      de <span>{formatPrice(isAnual ? plano.valor / 12 : plano.valor)}</span> por
                    </>
                  )}
                  {!plano.promo && plano.aPartirDe && <>A partir de:</>}
                </p>
              )}
              <div className={styles.price}>
                <span>{formatPrice(isAnual ? Number(valor) / 12 : valor)}</span>
                <small>/ mês</small>
                {isAnual && (
                  <Trigger
                    trigger="click"
                    placement="top"
                    overlay={
                      <Popover id="popover-assinatura" className={styles.popover}>
                        <Popover.Content>
                          <p className="m-0">
                            Pagamento anual de {formatPrice(valor)}. <br />
                            Parcelamento exclusivo em até 12x no cartão de crédito.
                          </p>
                        </Popover.Content>
                      </Popover>
                    }
                    rootClose
                  >
                    <Info className={styles.infoIcon} />
                  </Trigger>
                )}
              </div>
            </>
          )}
        </div>
        {isPlanoRedes ? (
          <Button
            text="Demonstração gratuita"
            className={styles.tryButton}
            onClick={handleSaibaMais}
          />
        ) : (
          <Button
            text="Testar por 7 dias grátis"
            className={styles.tryButton}
            onClick={handleTestar}
          />
        )}
        <p className={clsx(styles.cardText)}>Não é necessário cartão de crédito</p>
      </div>
      <Separator className={styles.separator} />
      <div className={styles.funcionalidades}>
        <div className={styles.funcionalidadesHeader}>
          {plano.nome === 'Agenda' ? (
            <>
              <h2>Principais</h2>
              <span>funcionalidades</span>
            </>
          ) : (
            <>
              <span>Tudo do</span>
              <h2>{plano.planoFull}</h2>
            </>
          )}
        </div>
        <ul>
          {plano.funcionalidades.map(funcionalidade => {
            const isString = typeof funcionalidade === 'string';

            return (
              <Funcionalidade
                descricao={isString ? funcionalidade : funcionalidade.name}
                small={!isString && funcionalidade.small}
                soon={!isString && funcionalidade.soon}
                popular={plano.mostPopular}
                key={isString ? funcionalidade : funcionalidade.name}
                info={!isString ? funcionalidade.info : undefined}
                isNew={!isString && funcionalidade.isNew}
                isPreLaunch={!isString && funcionalidade.isPreLaunch}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Card;
