import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import Container from 'react-bootstrap/Container';

import { Root } from '@/layouts';
import { Separator, Button } from '@/components';
import { planos, planosAnuais } from '@/constants/planos';
import { buttonEvents, precosEPlanosButtonNames } from '@/constants/analytics';

import Card from './Card';
import Hero from './Hero';
import FaleConosco from './FaleConosco';
import Faq from './FAQ';
import Detalhes from './Detalhes';

import * as styles from './planos.module.scss';
import { trackButtonClick } from '@/services/analytics';
import Switch from './Switch';
import { navigate } from 'gatsby';
import { routes } from '@/constants/routes';

const LayoutPage = () => {
  const refDetalhes = useRef<HTMLDivElement | null>(null);
  const [isAnual, setIsAnual] = useState(false);

  const handleComparar = () => {
    refDetalhes.current?.scrollIntoView({ behavior: 'smooth' });
    trackButtonClick({
      event: buttonEvents.precosEPlanos,
      buttonName: precosEPlanosButtonNames.compararPlanosActions,
    });
  };

  const handleTestar = () => {
    navigate(routes.cadastro);
    trackButtonClick({
      event: buttonEvents.precosEPlanos,
      buttonName: precosEPlanosButtonNames.testarGratisActions,
    });
  };

  const handleSwitchChange = () => setIsAnual(!isAnual);

  return (
    <Root variant="light" hero={<Hero title="Encontre o plano ideal para sua clínica crescer" />}>
      <div className={styles.container}>
        <div className="justify-content-center d-flex">
          <Switch handleChange={handleSwitchChange} isAnual={isAnual} />
        </div>

        <Container className="justify-content-center">
          <div className={clsx('justify-content-center', styles.list)}>
            {isAnual
              ? planosAnuais.map(plano => (
                  <Card
                    key={plano.nome}
                    plano={plano}
                    gaMetadata={{ event: buttonEvents.precosEPlanos }}
                  />
                ))
              : planos.map(plano => (
                  <Card
                    key={plano.nome}
                    plano={plano}
                    gaMetadata={{ event: buttonEvents.precosEPlanos }}
                  />
                ))}
          </div>
          <div className={styles.actions}>
            <div className={styles.buttons}>
              <Button
                text="Compare os planos"
                variant="outline-blue"
                onClick={handleComparar}
                className={styles.comparar}
              />
              <Button text="Quero testar grátis" variant="orange" onClick={handleTestar} />
            </div>
            <div>
              <span>
                * Pacotes de mensagens SMS, de Análise de Crédito, de Assinatura eletrônica e de
                WhatsApp são adquiridos à parte.
              </span>
            </div>
            <Separator className={styles.separator} />
          </div>
        </Container>
        <Container className="justify-content-center">
          <FaleConosco
            gaMetadata={{
              event: buttonEvents.precosEPlanos,
              buttonName: precosEPlanosButtonNames.falarEspecialistaFaleConosco,
            }}
          />
          <Detalhes ref={refDetalhes} />
        </Container>
        <Faq />
      </div>
    </Root>
  );
};

export default LayoutPage;
